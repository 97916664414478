body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Sidebar {
  z-index: 1200 !important;
}

.banner {
  -o-object-fit: cover;
  object-fit: cover;

  h1 {
    margin-top: 0px !important;
  }
}

.loadingGrid {
  width: 100%;
  min-height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.single-line {
  max-width: 98%;
  // display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.MainContent  {
  height: 100%;
}

.tiles {
  $maxElements: 100;
  @for $i from 1 to $maxElements {
    div:nth-child(#{$i}) {
      opacity: 0;
      animation: fadeIn 2s;
      animation-delay: #{$i/5}s;
      animation-fill-mode: forwards;
    }
  }
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.rjsf {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  padding: 0px;

  fieldset {
    border: none;
    padding: 0px;
    margin-bottom: 1rem;

    legend {
      font-size: 1.2rem;
      font-weight: 700;
      line-height: 1.2rem;
      margin-bottom: 1rem;
    }

    .form-group {
      display: flex;
      flex-direction: column;
      gap: 0.2rem;
      margin-bottom: 0.5rem;
      input, select {
        font-size: 0.95rem;
        padding: 0.5rem 0.2rem;
        outline: 0px;
        border-radius: 0px;
        border-width: 0px 0px 1px 0px;
      }
    }
  }
  .btn {
    text-align: center;
    background-color: #0b6bcb;
    color: #ffffff;
    border-radius: 6px;
    font-size: 0.875rem;
    font-weight: 700;
    padding-inline: 1rem;
    line-height: 1.5rem;
    width: 100%;
    outline: none;
    border: none;
    min-height: 2rem;
    cursor: pointer;
    &:hover {
      background-color: #0a65c1;
    }
  }
}

// button, .MuiCard-root *, .MuiCard-root .banner, .css-rx0qdg-JoyCardCover-root, .MuiInput-root, .MuiSelect-root {
//   border-radius: 0px !important;
// }